import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import "normalize.css";

import "@fontsource/archivo/400.css";
import "@fontsource/archivo/500.css";
import "@fontsource/archivo/600.css";
import "@fontsource/archivo/700.css";
import "@fontsource/archivo/800.css";

import { Helmet } from "react-helmet";
import { lightTheme } from "@logrock/pebbles";
import { down } from "styled-breakpoints";

import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

polyfillCountryFlagEmojis();

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.background}
  }

  html,
  * {
    font-family: "Twemoji Country Flags", "Archivo", sans-serif;

    ${down("mobile")} {
      text-align: center;
    }
  }

  html {
  scroll-padding-top: 70px; 

  a {
    color: ${({ theme }) => theme.colors.neutral[900]};

    &:visited {
      color: ${({ theme }) => theme.colors.neutral[900]};
    }
  }
}

`;

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8N1N3K5MHH"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-8N1N3K5MHH');
            `}
        </script>

        <script>
          {`(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
            
            mixpanel.init('314733a0531755c6d8936050e606d0ab');
            mixpanel.track('website.navigateTo', {'url': "/"});`}
        </script>

        <meta
          name="facebook-domain-verification"
          content="jtyhai25t9v799lckpuc7673lf4w6p"
        />

        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/21259273.js"
        ></script>
      </Helmet>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyle />
        {element}
      </ThemeProvider>
    </>
  );
};
